import Styles from './logo.module.css';

import classNames from 'classnames';
import * as React from 'react';

import Link from 'components/common/link/link';

type Variant = 'blue' | 'white';

interface LogoProps {
  href?: string;
  variant?: Variant;
  className?: string;
}

export const Logo: React.FC<LogoProps> = (props) => {
  const href = props.href || '/';
  const className = classNames(Styles.root, props.className, {
    [Styles.blue]: props.variant === 'blue',
    [Styles.white]: props.variant === 'white'
  });

  return (
    <Link href={href} className={className} external>
      Квартирка
    </Link>
  );
};

Logo.defaultProps = {
  href: '/',
  variant: 'blue'
};
