import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import { DEFAULT_FILTERS, DEFAULT_LOADINGS } from 'core/constants/default-values';
import { Currency } from 'core/entities';
import { Filters, FiltersLoadingType } from 'core/entities/filters';
import { SearchFilters, SearchPageParams } from 'core/entities/search';
import {
  getFiltersFromApiFilters,
  getFiltersFromQueryParams,
  setQueryParamsFromFilters
} from 'core/utils/filters/search-filter';
import { NOOP } from 'core/utils/NOOP';

export interface FiltersContextType {
  filters: Filters;
  mapShifted: boolean;
  isLanding: boolean;
  isLandingWithPrice: boolean;
  setFilters: Dispatch<SetStateAction<Filters>>;
  adultChecked: boolean;
  checkAdult: () => void;
  setMapShifted: (value: boolean) => void;
  isLoading: FiltersLoadingType;
  setLoading: Dispatch<SetStateAction<FiltersLoadingType>>;
}

export const FiltersContext = createContext<FiltersContextType>({
  filters: DEFAULT_FILTERS,
  mapShifted: false,
  isLanding: false,
  isLandingWithPrice: false,
  setFilters: NOOP,
  adultChecked: false,
  checkAdult: NOOP,
  setMapShifted: NOOP,
  isLoading: DEFAULT_LOADINGS,
  setLoading: NOOP
});

interface FiltersProviderProps {
  children: ReactNode;
  searchFilters: SearchPageParams;
  apiFilters: SearchFilters;
  currency: Currency;
  isLanding?: boolean;
}

const hasMapFilters = (filters: Filters) => {
  return compact(Object.values(filters.map)).length > 0;
};

const withUseShallow = (isLanding: boolean, searchFilters: SearchPageParams) => {
  let useShallow = !isLanding;
  if (useShallow && searchFilters.boundaryKey && searchFilters.boundaryKey.length > 0) {
    useShallow = false;
  }
  return useShallow;
};

export const FiltersProvider = ({
  children,
  searchFilters,
  apiFilters,
  currency,
  isLanding = false
}: FiltersProviderProps) => {
  const mounted = useRef(false);
  const firstFilters = useRef({
    ...getFiltersFromQueryParams(searchFilters, currency),
    ...getFiltersFromApiFilters(apiFilters, searchFilters, currency)
  });
  const router = useRouter();
  const [filters, setFilters] = useState<Filters>(firstFilters.current);
  const [mapShifted, setMapShifted] = useState(hasMapFilters(firstFilters.current));
  const [isLandingPage, setIsLandingPage] = useState(isLanding);
  const [isLandingWithPrice, setIsLandingWithPrice] = useState(
    Boolean(isLanding && (apiFilters.price || apiFilters.min_price))
  );
  const [adultChecked, setAdultChecked] = useState(false);
  const checkAdult = () => setAdultChecked(true);

  // Возможно в будущем можно переписать на useReducer вместе с остальным
  const [isLoading, setLoading] = useState(DEFAULT_LOADINGS);

  useEffect(() => {
    const checkPrice =
      (searchFilters.minPrice && searchFilters.minPrice > currency.maxPrice) ||
      (searchFilters.maxPrice && searchFilters.maxPrice > currency.maxPrice);

    if ((mounted.current && !isEqual(firstFilters.current, filters)) || checkPrice) {
      firstFilters.current = filters;
      const splittedUrl = router.asPath.split('?');
      let pathname;
      if (isLandingPage) {
        const params = compact(splittedUrl[0].split('/'));
        const isMap = params.slice().pop() === 'map';
        pathname = `/${params[0]}/${params[1]}/${isMap ? 'map/' : ''}`;
        setIsLandingPage(false);
        setIsLandingWithPrice(false);
      } else {
        pathname = splittedUrl[0];
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(
        {
          pathname: router.pathname
        },
        {
          pathname,
          query: setQueryParamsFromFilters(filters, currency)
        },
        {
          shallow: withUseShallow(isLanding, searchFilters)
        }
      );
    } else {
      mounted.current = true;
    }
  }, [filters, isLandingPage]);

  return (
    <FiltersContext.Provider
      value={{
        filters,
        mapShifted,
        isLanding: isLandingPage,
        isLandingWithPrice: isLandingWithPrice,
        setFilters,
        adultChecked,
        checkAdult,
        setMapShifted,
        isLoading,
        setLoading
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
