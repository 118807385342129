import Styles from './link.module.css';

import classNames from 'classnames';
import Link from 'next/link';
import * as React from 'react';

import { AppParams } from 'controllers/app/app-params';

type Variant = 'blue' | 'black' | 'white' | 'red' | 'button' | 'light-button';

interface CustomLinkProps {
  children: React.ReactChild;
  href: string;
  visited?: boolean;
  underlined?: boolean;
  variant?: Variant;
  className?: string;
  newBlank?: boolean;
  download?: boolean;
  external?: boolean;
  appParams?: AppParams;
  isApp?: boolean;
  itemProp?: boolean;
  onClick?: () => Promise<void>;
}

const needRenderCommonUlr = (props: CustomLinkProps) =>
  props.external || props.appParams || props.newBlank || props.download || props.isApp;

const needRenderEmptyUrl = (props: CustomLinkProps) => props.onClick && !props.href;

export const CustomLink: React.FC<CustomLinkProps> = (props) => {
  let component = null;
  let href = props.href;
  if (props.appParams) {
    href = `${href}?${props.appParams.stringify()}`;
  }

  const className = classNames(Styles.root, props.className, {
    [Styles.visited]: props.visited,
    [Styles.underlined]: props.underlined,
    [Styles.black]: props.variant === 'black',
    [Styles.white]: props.variant === 'white',
    [Styles.red]: props.variant === 'red',
    [Styles.button]: props.variant === 'button',
    [Styles.lightButton]: props.variant === 'light-button'
  });

  if (needRenderCommonUlr(props)) {
    component = (
      <a
        href={href}
        className={className}
        target={props.newBlank ? '_blank' : '_self'}
        rel={props.newBlank ? 'noopener noreferrer' : ''}
        download={props.download}
      >
        {props.children}
      </a>
    );
  } else if (needRenderEmptyUrl(props)) {
    component = (
      <div className={className} onClick={props.onClick}>
        {props.children}
      </div>
    );
  } else {
    component = (
      <Link className={className} href={props.href} itemProp={props.itemProp ? 'item' : ''}>
        {props.children}
      </Link>
    );
  }

  return component;
};

CustomLink.defaultProps = {
  variant: 'blue',
  visited: true,
  underlined: false,
  external: false
};

export default CustomLink;
