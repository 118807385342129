import styles from './footer.module.css';

import classNames from 'classnames';

import { Chaport, FooterContext, FooterSectionCodeName } from 'core/entities';

import { useBreakpoints } from 'hooks/use-window-dimensions';

import { BackToAdminButton } from 'components/back-to-admin-button/back-to-admin-button';
import { FooterInfo } from 'components/layout/footer/footer-info/footer-info';
import { FooterLinksSection } from 'components/layout/footer/footer-links-section/footer-links-section';
import { SlimFooter } from 'components/layout/footer/slim-footer/slim-footer';

interface FooterProps {
  className?: string;
  slim?: boolean;
  isCentral?: boolean;
  noFooter?: boolean;
  footerContext: FooterContext;
  user: {
    id: number;
    isImpersonated: boolean;
  };
  mainHost: string;
  chaport: Chaport;
}

export const Footer = (props: FooterProps) => {
  const { isMobile } = useBreakpoints();

  if (props.noFooter || (props.slim && !props.footerContext.shortFooter) || !props.footerContext.fullFooter) {
    return null;
  }

  if (props.slim) {
    return <SlimFooter className={props.className} sections={props.footerContext.shortFooter || []} />;
  }

  return (
    <footer className={classNames(styles.root, props.className, { [styles.central]: props.isCentral })} id='footer'>
      <div className={styles.container}>
        {props.user.isImpersonated && <BackToAdminButton userId={props.user.id} />}
        <ul className={styles.sections}>
          {props.footerContext.fullFooter.map((section) => {
            const isAppsSection = section.codeName === FooterSectionCodeName.apps;
            const isSocialSection = section.codeName === FooterSectionCodeName.social;
            return (
              <FooterLinksSection
                className={classNames(styles.section, { [styles.apps]: isAppsSection })}
                key={section.codeName}
                linksSection={section}
                isAppsSection={isAppsSection}
                isSocialSection={isSocialSection}
                isMobile={isMobile}
                mainHost={props.mainHost}
                user={props.user}
                chaport={props.chaport}
              />
            );
          })}
        </ul>
        <FooterInfo />
      </div>
    </footer>
  );
};
