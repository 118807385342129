import { City } from 'core/entities/geo';

export interface Filters {
  buildingTypes: BuildingTypesFilters;
  dates: DatesFilters;
  features: FeaturesFilters;
  rooms: RoomsFilters;
  roomsExtraOptions: RoomsExtraOptionsFilters;
  guests: GuestsFilters;
  guestsSelected: boolean;
  sleepingPlaces: number;
  minBeds: number;
  prices: PricesFilters;
  map: MapFilters;
  pointName?: string;
  city?: City;
}

export interface BuildingTypesFilters {
  flat?: boolean;
  cottage?: boolean;
  room?: boolean;
}

export enum DatesFilterMacros {
  today = 'today',
  tomorrow = 'tomorrow',
  overmorrow = 'overmorrow',
  dayAfterTomorrow = 'day_after_tomorrow'
}

export interface DatesFilters {
  arrival: Optional<string>;
  departure: Optional<string>;
  duration: Optional<number>;
}

// Метка добавления нового фильтра
export interface FeaturesFilters {
  instantBooking?: boolean;
  reviewGood?: boolean;
  photocheck?: boolean;
  studio?: boolean;
  internet?: boolean;
  reportDocs?: boolean;
  parking?: boolean;
  parties?: boolean;
  smoking?: boolean;
  animals?: boolean;
  conditioner?: boolean;
  washingMachine?: boolean;
  fridge?: boolean;
  teapot?: boolean;
  cooker?: boolean;
  hairDryer?: boolean;
  iron?: boolean;
  spa?: boolean;
  sauna?: boolean;
  bbq?: boolean;
  pool?: boolean;
  billiard?: boolean;
  karaoke?: boolean;
  fireplace?: boolean;
  babies?: boolean;
  babyBed?: boolean;
}

export interface RoomsFilters {
  one?: boolean;
  two?: boolean;
  three?: boolean;
  four?: boolean;
  five?: boolean;
}

export interface RoomsExtraOptionsFilters {
  bathroom?: boolean;
  kitchen?: boolean;
}

export interface GuestsFilters {
  adults: number;
  children: number;
  babies: number;
}

export interface PricesFilters {
  filtered: {
    min: number;
    max: number;
  };
  range: {
    min: number;
    max: number;
  };
}

export interface MapFilters {
  // box
  minLat?: Optional<number>;
  minLng?: Optional<number>;
  maxLat?: Optional<number>;
  maxLng?: Optional<number>;

  // center
  lng?: Optional<number>;
  lat?: Optional<number>;

  zoom?: Optional<number>;

  boundaryKey?: number;
}

// Метка добавления нового фильтра
export enum FiltersFeaturesNames {
  instantBooking = 'Быстрое бронирование',
  photocheck = 'Фотографии проверены',
  reviewGood = 'Хорошие отзывы',
  studio = 'Студия',
  internet = 'Интернет',
  reportDocs = 'Отчетные документы',
  parking = 'Парковка',
  parties = 'Вечеринки',
  smoking = 'Разрешено курить',
  animals = 'Можно с животными',
  conditioner = 'Кондиционер',
  washingMachine = 'Стиральная машина',
  fridge = 'Холодильник',
  teapot = 'Чайник',
  cooker = 'Плита',
  hairDryer = 'Фен',
  iron = 'Утюг',
  spa = 'Джакузи',
  sauna = 'Баня или сауна',
  bbq = 'Мангал, гриль',
  pool = 'Бассейн',
  billiard = 'Бильярд',
  karaoke = 'Караоке',
  fireplace = 'Камин',
  babies = 'Подходит для детей',
  babyBed = 'Детская кроватка'
}

export interface FiltersLoadingType {
  calendar: boolean;
  guests: boolean;
  buildingTypes: boolean;
  prices: boolean;
  features: boolean;
  mobileFilters: boolean;
}

export interface FilterControl {
  opened: boolean;
  onOpen: () => void;
  onClose: () => void;
}
