import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';

import { DEFAULT_CURRENCY } from 'core/constants/default-values';
import { Filters } from 'core/entities/filters';
import { serializeQuery } from 'core/utils/common';
import { setQueryParamsFromFilters } from 'core/utils/filters/search-filter';

import { CommonContext } from 'contexts/common';
import { FiltersContext } from 'contexts/filters/filters';

const checkIsMapPage = (path: string) => path.indexOf('/map') !== -1;

const getPathWithMap = (path: string) => {
  if (!checkIsMapPage(path)) {
    return path + 'map/';
  }
  return path;
};

const getPathWithoutMap = (path: string) => {
  if (checkIsMapPage(path)) {
    return path.replace('map/', '');
  }
  return path;
};

export const useFlatsRoutes = (mainHost: string) => {
  const router = useRouter();
  const { context } = useContext(CommonContext);
  const { mapShifted } = useContext(FiltersContext);
  const path = router.asPath.split('?')[0];
  const [isMapPage, setIsMapPage] = useState(checkIsMapPage(router.pathname));
  const currency = context ? context.currencies.current : DEFAULT_CURRENCY;

  useEffect(() => {
    setIsMapPage(checkIsMapPage(path));
  }, [router]);

  const getQueryString = useCallback(
    (filters: Filters) => {
      const query = serializeQuery(setQueryParamsFromFilters(filters, currency));
      const queryString = query.length > 0 ? `?${query}` : '';
      return queryString;
    },
    [setQueryParamsFromFilters, currency]
  );

  const getQueryStringWithoutMapFilters = useCallback(
    (filters: Filters) => {
      const filtersWithoutMap: Filters = {
        ...filters,
        map: {
          lng: null,
          lat: null,
          zoom: null,
          maxLat: null,
          maxLng: null,
          minLat: null,
          minLng: null
        }
      };
      const query = serializeQuery(setQueryParamsFromFilters(filtersWithoutMap, currency));
      const queryString = query.length > 0 ? `?${query}` : '';
      return queryString;
    },
    [setQueryParamsFromFilters, currency]
  );

  const goToFlatsListPage = useCallback(
    (filters: Filters, url: Optional<string> = null) => {
      let flatsListUrl: string;
      const query = mapShifted ? getQueryString(filters) : getQueryStringWithoutMapFilters(filters);

      if (url) {
        const mainUrl = new URL(url);
        flatsListUrl = `https://${mainHost}${getPathWithoutMap(mainUrl.pathname)}${getQueryString(filters)}`;
      } else {
        flatsListUrl = `https://${mainHost}${path ? getPathWithoutMap(path) : ''}${query}`;
      }

      const params = new URLSearchParams(flatsListUrl.split('?')[1]);
      params.append('fromMap', 'true');

      flatsListUrl = `${flatsListUrl.split('?')[0]}?${params.toString()}`;

      if (typeof window === 'object') {
        window.location.href = flatsListUrl;
      }
    },
    [path, mapShifted]
  );

  const goToFlatsMapPage = useCallback(
    (filters: Filters, url: Optional<string> = null) => {
      let mapUrl;
      if (url) {
        const mainUrl = new URL(url);
        mapUrl = `https://${mainHost}${getPathWithMap(mainUrl.pathname)}${getQueryString(filters)}`;
      } else {
        const getParams =
          typeof window === 'object' && window.location.search.length > 1 ? getQueryString(filters) : '';
        mapUrl = `https://${mainHost}${path ? getPathWithMap(path) : ''}${getParams}`;
      }

      if (typeof window === 'object') {
        window.location.href = mapUrl;
      }
    },
    [path]
  );

  return { isMapPage, goToFlatsListPage, goToFlatsMapPage };
};
