import styles from './footer-links-section.module.css';

import classNames from 'classnames';
import { useCallback } from 'react';

import { HIDE_CHAPORT } from 'core/constants/chaport';
import { FOOTER_TEXT_INSTALL_APP, FOOTER_TEXT_ONLINE_CHAT, FOOTER_TEXT_USEFULL } from 'core/constants/footer';
import { Chaport, FooterLinksSection as FooterLinksSectionType } from 'core/entities';

import CustomLink from 'components/common/link/link';
import { Text } from 'components/common/text/text';

interface FooterLinksSectionProps {
  className?: string;
  linksSection: FooterLinksSectionType;
  isAppsSection: boolean;
  isSocialSection: boolean;
  isMobile: boolean;
  mainHost: string;
  user: {
    id: number;
    isImpersonated: boolean;
  };
  chaport: Chaport;
}

export const FooterLinksSection = (props: FooterLinksSectionProps) => {
  const openChaport = () => {
    if (typeof window !== 'undefined' && window.chaport) {
      window.chaport.q('showLauncher');
      window.chaport.q('open');
      return false;
    }
    return false;
  };

  const getSectionTitle = useCallback(() => {
    if (props.isAppsSection && props.isMobile) {
      return FOOTER_TEXT_INSTALL_APP;
    }
    return props.linksSection.name;
  }, [props.isAppsSection, props.isMobile]);

  return (
    <li
      className={classNames(styles.root, props.className, {
        [styles.apps]: props.isAppsSection,
        [styles.social]: props.isSocialSection
      })}
    >
      <Text className={styles.title} As='h4' size={16}>
        {getSectionTitle()}
      </Text>
      {props.isAppsSection && props.isMobile && (
        <Text className={styles.description} As='p' size={16}>
          {FOOTER_TEXT_USEFULL}
        </Text>
      )}
      <div className={classNames(styles.links, styles[props.linksSection.codeName])}>
        {!HIDE_CHAPORT && props.linksSection.codeName === 'helpSection' && (
          <div role='presentation' onClick={openChaport} className={styles.onlineChat}>
            {FOOTER_TEXT_ONLINE_CHAT}
          </div>
        )}
        {props.linksSection.links.map((link) => (
          <CustomLink
            className={classNames(styles.link, styles[link.codeName])}
            key={link.codeName}
            href={link.url}
            external
          >
            {link.name}
          </CustomLink>
        ))}
      </div>
    </li>
  );
};
