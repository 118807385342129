import { addDays, compareAsc, format, getYear, isSameDay, isSameMonth, startOfDay, startOfMonth } from 'date-fns';
import { ru } from 'date-fns/locale';

import { dateFormat } from 'core/constants/date-formats';
import { FlatPrices } from 'core/entities/flats';

// в date-fns они с точками
export const SHORT_MONTH_NAMES = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
export const LONG_MONTH_NAMES = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];
export const SHORT_DAYS_NAMES = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

export function monthRange(from: Date, count: number): Date[] {
  const months = [];

  let month = new Date(from.getFullYear(), from.getMonth());

  let counter = 0;
  while (counter < count) {
    months.push(month);

    counter = counter + 1;
    month = new Date(from.getFullYear(), from.getMonth() + counter);
  }
  return months;
}

export function futureMonths(count = 12, includeStart = false): Date[] {
  const today = startOfDay(new Date());
  const months = monthRange(today, count);
  if (!includeStart) {
    months[0] = today;
  }
  return months;
}

export function weeksRange(start: Date, end?: Date): Date[][] {
  const finish = end || new Date(start.getFullYear(), start.getMonth() + 1);
  const result = [];
  let weekBuffer = [];
  let dayBuffer = start;
  while (dayBuffer < finish) {
    weekBuffer.push(dayBuffer);
    if (dayBuffer.getDay() === 0) {
      result.push(weekBuffer);
      weekBuffer = [];
    }
    dayBuffer = addDays(dayBuffer, 1);
  }
  if (weekBuffer.length) {
    result.push(weekBuffer);
  }
  return result;
}

export function pastWeeks(before: Date) {
  return weeksRange(startOfMonth(before), before);
}

export function formatDay(day: Date): string {
  const month = day.getMonth() + 1;
  const date = day.getDate();

  return [day.getFullYear(), month < 10 ? `0${month}` : month, date < 10 ? `0${date}` : date].join('-');
}

export function compareDays(first: Date, second: Date): number {
  if (isSameDay(first, second)) {
    return 0;
  }
  return compareAsc(first, second);
}

export function shortDateRangeFormat(start: Date, end: Date) {
  const startDay = start.getDate();
  const startMonth = SHORT_MONTH_NAMES[start.getMonth()];
  const endDay = new Date(end).getDate();
  const endMonth = SHORT_MONTH_NAMES[end.getMonth()];
  if (isSameMonth(start, end)) {
    return `${startDay}..${endDay}\xa0${endMonth}`;
  }
  return `${startDay}\xa0${startMonth}..${endDay}\xa0${endMonth}`;
}

export function longDateRangeFormat(start: Date, end: Date) {
  let startFormat = dateFormat.dayFullMonthYear;
  let endFormat = dateFormat.dayFullMonthYear;
  if (getYear(start) === getYear(end)) {
    startFormat = dateFormat.dayMonth;
    endFormat = dateFormat.dayFullMonthYear;
  }
  const startFns = format(start, startFormat, { locale: ru });
  const endFns = format(end, endFormat, { locale: ru });
  return `${startFns} — ${endFns}`;
}

export function getDifferenceInTime(start: Date, end: Date) {
  return start.getTime() - end.getTime();
}

export function getDifferenceInDays(start: Date, end: Date) {
  return getDifferenceInTime(start, end) / (1000 * 3600 * 24);
}

export function formatLogTime(logTime: string) {
  return format(new Date(logTime), dateFormat.log, { locale: ru });
}

export function daysRange(month: Date): Date[] {
  let current = new Date(month.getFullYear(), month.getMonth());
  const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1);

  const days = [];

  while (current < nextMonth) {
    days.push(current);
    current = new Date(month.getFullYear(), month.getMonth(), current.getDate() + 1);
  }

  return days;
}

interface InheritedPrice {
  value: number;
  inherited: boolean;
}

const dayIsWeekend = (day: Date) => [5, 6].includes(day.getDay());

export function getDailyPrice(prices: FlatPrices, day: Date): InheritedPrice {
  let inherited = false;
  let price = 0;
  const dayStr = format(day, dateFormat.iso);
  // eslint-disable-next-line
  if (prices.daily.hasOwnProperty(dayStr)) {
    price = prices.daily[dayStr];
  }
  if (price && price > 0) {
    return { value: price, inherited };
  }
  price = prices.default.daily;
  inherited = true;
  if (prices.default.weekend && dayIsWeekend(day)) {
    price = prices.default.weekend;
  }

  return { value: price, inherited };
}

export function getDurationPrice(prices: FlatPrices, day: Date): InheritedPrice {
  let duration = prices.minimumStay;
  let inherited = true;
  const dayStr = format(day, dateFormat.iso);
  // eslint-disable-next-line
  if (prices.durations.hasOwnProperty(dayStr)) {
    duration = prices.durations[dayStr];
    inherited = false;
  }
  return { value: duration, inherited };
}

export const isWithinRange = (day: Date, start: Date, end: Date) => {
  const dayTime = day.getTime();
  return dayTime >= Math.min(start.getTime(), end.getTime()) && dayTime <= Math.max(start.getTime(), end.getTime());
};

const _formatSingleDate = (date: Date) => {
  return format(date, dateFormat.dayShortMonth, { locale: ru });
};

const _formatDateRangeString = (start: Date, end: Date) => {
  return `${format(start, dateFormat.day, { locale: ru })}-${format(end, dateFormat.dayShortMonth, { locale: ru })}`;
};

export const formatDateRange = (dates: Date[]) => {
  const sortedDates = dates.sort((a, b) => a.getTime() - b.getTime());

  const result: string[] = [];

  let rangeStart: Date = sortedDates[0];
  let rangeEnd: Date = sortedDates[0];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < sortedDates.length; i++) {
    const current = sortedDates[i];
    const nextDay = addDays(rangeEnd, 1);

    if (isSameDay(nextDay, current)) {
      rangeEnd = current;
    } else {
      result.push(
        isSameDay(rangeStart, rangeEnd) ? _formatSingleDate(rangeStart) : _formatDateRangeString(rangeStart, rangeEnd)
      );

      rangeStart = current;
      rangeEnd = current;
    }
  }

  result.push(
    isSameDay(rangeStart, rangeEnd) ? _formatSingleDate(rangeStart) : _formatDateRangeString(rangeStart, rangeEnd)
  );

  return result;
};
